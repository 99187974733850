import { FC } from "react"
import { Avatar, Divider, Grid, IconButton, Typography } from "@mui/material"
import Lottie from "lottie-react"
import loading from "../utils/loading.json"
import { ArrowBack } from "@mui/icons-material"

interface LoadingProps {
    message: string
    merchantName?: string
}
const Loading: FC<LoadingProps> = ({ message, merchantName }) => {
    return (
        <Grid
            container
            sx={{
                display: "flex",
                justifyContent: "start",
                flexDirection: "column",
                px: 4,
            }}
        >
            <Grid item sx={{ justifyContent: "start" }}>
                <Avatar
                    variant="square"
                    alt="Avatar"
                    src={process.env.PUBLIC_URL + "/assets/logo.svg"}
                    sx={{
                        top: 0,
                        left: 0,
                        width: 100,
                        height: 130,
                        mt: -2,
                        bgcolor: "transparent",
                    }}
                />
            </Grid>
            {merchantName && (
                <Grid
                    item
                    sm={12}
                    xs={12}
                    sx={{
                        mt: -4,
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                    }}
                >
                    <IconButton
                        onClick={() =>
                            (window.location.href =
                                localStorage.getItem("return_url") ?? "")
                        }
                    >
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        {merchantName}
                    </Typography>
                </Grid>
            )}
            <Divider
                orientation="horizontal"
                sx={{
                    mb: 3,
                    mt: merchantName ? 3 : -3,
                    ml: { xs: 0, md: merchantName ? "3em" : 0 },
                }}
            />
            <Grid
                item
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: {
                        xs: "calc(100vh - 300px)",
                        md: "calc(100vh - 200px)",
                    },
                }}
            >
                <Grid
                    item
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        mt: 2,
                        minHeight: "50vh",
                    }}
                >
                    <Lottie
                        animationData={loading}
                        loop={true}
                        style={{ width: "5em", height: "5em" }}
                    />
                    <Typography variant="body1" sx={{ fontWeight: "bold", color: "primary.main" }}>
                        {message}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Loading
