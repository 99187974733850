import { useQuery } from "@tanstack/react-query"
import axios from "../utils/api/axios"

// Get an Entity Details
const useGetEntity = (key: string, endPoint: string, id: string) => {

    return useQuery([key, endPoint, id], () =>
        axios
            .get(`/${endPoint}/${id}`)
            .then((response) => response.data)
    )
}

export default useGetEntity
