import { useState, ChangeEvent } from "react";
import {
  TextField,
  InputAdornment,
  Box,
  Grid,
  Typography,
} from "@mui/material";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CardFormProps {}

const CardForm: React.FC<CardFormProps> = () => {
  const [cardNumber, setCardNumber] = useState("");
  const [cardType, setCardType] = useState("");

  const handleCardNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setCardNumber(inputValue);
    detectCardType(inputValue);
  };

  const detectCardType = (inputValue: string) => {
    const visaPattern = /^4/;
    const mastercardPattern = /^5[1-5]/;

    if (visaPattern.test(inputValue)) {
      setCardType("visa");
    } else if (mastercardPattern.test(inputValue)) {
      setCardType("mastercard");
    } else {
      setCardType("");
    }
  };

  const getCardLogo = (): string | null => {
    if (cardType === "visa") {
      return process.env.PUBLIC_URL + "/assets/visa-logo.svg";
    } else if (cardType === "mastercard") {
      return process.env.PUBLIC_URL + "/assets/Mastercard-Logo.png";
    } else {
      return null;
    }
  };

  return (
    <Box
      sx={{
        maxHeight: {sm:`calc(100vh - 452px)`, xs: `100%`},
        overflow: "auto",
        pr: 1,        
        transition: "transform 0.4s ease",      
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item sm={12} xs={12}>
          <Typography sx={{ fontWeight: "bold", fontSize: 16 }}>
            Billing Information
          </Typography>
        </Grid>
        <Grid item sm={6} xs={12} >
          <TextField
            size="small"
            label="Phone Number"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField size="small" label="Email" variant="outlined" fullWidth />
        </Grid>
        <Grid item sm={12} xs={12}>
          <Typography sx={{ fontWeight: "bold", fontSize: 16, mt: 2 }}>
            Card Detail
          </Typography>
        </Grid>
        <Grid item sm={12} xs={12}>
          <TextField
            size="small"
            label="Card Number"
            value={cardNumber}
            onChange={handleCardNumberChange}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {cardType === "" && (
                    <>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/visa-logo.svg"}
                        alt={cardType}
                        style={{ width: "30px", height: "auto" }}
                      />
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/Mastercard-Logo.png"
                        }
                        alt={cardType}
                        style={{ width: "30px", height: "auto" }}
                      />
                    </>
                  )}
                  {cardType && (
                    <img
                      src={getCardLogo() || ""}
                      alt={cardType}
                      style={{ width: "30px", height: "auto" }}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            size="small"
            label="Card Holder Name"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item sm={3} xs={6}>
          <TextField
            size="small"
            label="Exp Date"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item sm={3} xs={6}>
          <TextField size="small" label="CVC" variant="outlined" fullWidth />
        </Grid>
        <Grid item sm={12} xs={12}>
          <Typography sx={{ fontWeight: "bold", fontSize: 16, mt: 2 }}>
            Billing Address
          </Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            size="small"
            label="Country"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField size="small" label="City" variant="outlined" fullWidth />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            size="small"
            label="Sub City"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item sm={3} xs={6}>
          <TextField size="small" label="Woreda" variant="outlined" fullWidth />
        </Grid>
        <Grid item sm={3} xs={6}>
          <TextField
            size="small"
            label="House No"
            variant="outlined"
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CardForm;
