import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

const useBreakPoints = () => {
    const theme = useTheme()
    const xs = useMediaQuery(theme.breakpoints.up("xs"))
    const sm = useMediaQuery(theme.breakpoints.up("sm"))
    const md = useMediaQuery(theme.breakpoints.up("md"))
    const lg = useMediaQuery(theme.breakpoints.up("lg"))

    return {
        xs,
        sm,
        md,
        lg,
    }
}

export default useBreakPoints
