import Box from "@mui/material/Box"
import Avatar from "@mui/material/Avatar"
import { useEffect, useRef, useState } from "react"

interface Avatar {
    image: string
    id: any
    isSelected: boolean
    handleSelection: (id: any) => void
    setPaymentMethod: React.Dispatch<React.SetStateAction<string>>
}

function ZoomableAvatar(props: Avatar) {
    const { image, id, isSelected, handleSelection, setPaymentMethod } = props
    const btnRef = useRef()
    const [isClicked, setIsClicked] = useState(false)

    const handleBoxClick = () => {
        setIsClicked(!isClicked)
        handleSelection(id)
    }
    useEffect(() => {
        if (!isClicked) setPaymentMethod("")
    }, [isClicked])
    return (
        <Box
            ref={btnRef}
            sx={{
                position: "relative",
                width: 150,
                height: 90,
                overflow: "hidden",
                cursor: "pointer",
                border: isSelected && isClicked ? "3px solid #fdbc3d" : "0px",
                borderRadius: 2,
                backgroundImage:
                    "linear-gradient(90deg, #ffffff 0%, #fff9e6 50%, #fff9e6 100%)",
            }}
            onClick={handleBoxClick}
        >
            <Avatar
                variant="square"
                sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    bgcolor: "transparent",
                    transition: "transform 0.3s ease",
                    transform:
                        isSelected && isClicked ? "scale(1.2)" : "scale(1)",
                }}
            >
                <img
                    src={image}
                    alt="Avatar"
                    style={{
                        width: isSelected && isClicked ? "80%" : "60%",
                        height: isSelected && isClicked ? "90%" : "80%",
                    }}
                />
            </Avatar>
        </Box>
    )
}

export default ZoomableAvatar
