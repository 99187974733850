import { ArrowBack } from "@mui/icons-material"
import {
    Avatar,
    Button,
    Divider,
    Grid,
    IconButton,
    Typography,
} from "@mui/material"
import { Box } from "@mui/system"
import useBreakPoints from "../hooks/useBreakPoints"

interface SuccessPageProps {
    merchantName: string
}

// Success Page Component
const SuccessPage = ({ merchantName }: SuccessPageProps) => {
    const { sm } = useBreakPoints()
    return (
        <Grid
            container
            sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                px: "2em",
            }}
        >
            <Grid item sx={{ justifyContent: "start" }}>
                <Avatar
                    variant="square"
                    alt="Avatar"
                    src={process.env.PUBLIC_URL + "/assets/logo.svg"}
                    sx={{
                        top: 0,
                        left: 0,
                        width: 100,
                        height: 130,
                        mt: -2,
                        bgcolor: "transparent",
                    }}
                />
            </Grid>
            {merchantName && (
                <Grid
                    item
                    sm={12}
                    xs={12}
                    sx={{
                        mt: -4,
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                    }}
                >
                    <IconButton
                        onClick={() =>
                            (window.location.href =
                                localStorage.getItem("return_url") ?? "")
                        }
                    >
                        <ArrowBack />
                    </IconButton>
                    <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", overflow: "auto" }}
                    >
                        {merchantName}
                    </Typography>
                </Grid>
            )}
            <Divider
                orientation="horizontal"
                sx={{
                    mb: 3,
                    mt: merchantName ? 3 : -3,
                    ml: { xs: 0, md: merchantName ? "3em" : 0 },
                }}
            />
            <Grid
                item
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    maxHeight: "calc(100vh - 80px)",
                    overflowY: "auto",
                    overflowX: "hidden",
                    mt: "2em",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "0.3em",
                    }}
                >
                    <Box>
                        <Avatar
                            variant="square"
                            sx={{
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                bgcolor: "transparent",
                            }}
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/assets/success.png"
                                }
                                alt="Avatar"
                                style={{
                                    width: sm ? "40%" : "70%",
                                }}
                            />
                        </Avatar>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            width: "100%",
                            gap: "0.5em"
                        }}
                    >
                        <Typography
                            variant="h6"
                            component="h4"
                            sx={{ fontWeight: 900, color: "success.main" }}
                        >
                            {" "}
                            {"Congratulations!"}{" "}
                        </Typography>
                        <Typography
                            variant="body1"
                            component="h4"
                            sx={{ fontWeight: "bold" }}
                        >
                            {" "}
                            {"Payment Processed Succesfully!"}{" "}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Box
                sx={{
                    mt: "2em",
                }}
            >
                <Grid
                    container
                    justifyContent="center"
                    alignItems="end"
                    sx={{ minHeight: "4em" }}
                >
                    <Grid item>
                        <Button
                            sx={{
                                textTransform: "none",
                                color: "black",
                                bgcolor: "#f2f2f2",
                                ":hover": {
                                    bgcolor: "#f2f2f2",
                                },
                                borderRadius: 2,
                            }}
                            onClick={() =>
                                (window.location.href =
                                    localStorage.getItem("return_url") ?? "")
                            }
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    fontWeight: "bold",
                                    py: "0.3em",
                                    px: "3em",
                                }}
                            >
                                Go Home
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}
export default SuccessPage
