import { useMutation } from "@tanstack/react-query"
import axios from "../utils/api/axios"

// Pay subscription with the selected payment method
const useSubscribe = (id: any) => {
// TODO: To be used when the backend is ready
//     const device = ["Android", "iPhone"].some((device) =>
//     window.navigator.userAgent.includes(device)
//   )
//     ? "SDK"
//     : "WEB";

    return useMutation((data: any) =>
        axios
            .post(`/subscriptions/${id}/checkout`, {...data,
                payment_platform:  "WEB",
            })
    )
}

export default useSubscribe