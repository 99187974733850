import { createContext, useContext, useMemo, useState } from "react";

import { ErrorResponse } from "../components/errorPage";

interface CheckoutContextType {
  code: string
  discountPaymentMethods?: string[]
  error?: ErrorResponse
  setCode: (code: string)=> void
  setDiscountPaymentMethods: (paymentMethodIds: string[])=> void
  setError: (error: ErrorResponse) => void
}

const CheckoutContext = createContext<CheckoutContextType | undefined>(undefined);

export function CheckoutProvider({ children }: { children: React.ReactNode }) {
  const [code, setCode] = useState<CheckoutContextType["code"]>("")
  const [discountPaymentMethods, setDiscountPaymentMethods] = useState<CheckoutContextType["discountPaymentMethods"]>([])
  const [error, setError] = useState<CheckoutContextType["error"]>(undefined)

  const value = useMemo(() => {
    return {
      code,
      discountPaymentMethods,
      error,
      setCode,
      setDiscountPaymentMethods,
      setError,
    };
  }, [discountPaymentMethods, error]);

  return (
    <CheckoutContext.Provider value={value}>
      {children}
    </CheckoutContext.Provider>
  );
}
export function useCheckoutContext(): CheckoutContextType {
    const context = useContext(CheckoutContext);
    if (!context) {
      throw new Error("useCheckoutContext must be used within a CheckoutProvider");
    }
    return context;
  }
export default CheckoutContext