import { useEffect } from "react"
import Loading from "../components/loading"

// Redirect to Merchant Page Component
function RedirectPage() {
    const returnUrl = localStorage.getItem("return_url")

    useEffect(() => {
        window.location.href = returnUrl ?? ""
        localStorage.removeItem("return_url")
    }, [])

    return <Loading message="Redirecting to Home Page" />
}
export default RedirectPage
