export function CapitalizeWordAndAddLy(word: string){
    if (word?.length === 0) {
      return word;
    }

    switch(word){
      case "YEAR":
          return "Yearly"
      case "MONTH":
          return "Monthly"
      case "DAY":
        return "Daily"
      default:
        return ""
    }

}
