import { useMutation } from "@tanstack/react-query"
import { useSnackbar } from "notistack"

import axios from "../utils/api/axios"

// Update an entity data
const useUpdateEntity = (endPoint: string) => {
    const { enqueueSnackbar } = useSnackbar()

    return useMutation((data: any) =>
        axios
            .patch(`${endPoint}`, data),
            {
                onError: (error: any)=>
                    enqueueSnackbar(
                        error?.response?.data?.error?.message ||   
                        error?.message ||                          
                            "Network Error!",
                        {
                            variant: "error",
                        }
                    )
            }
    )
}

export default useUpdateEntity