import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { CheckoutProvider } from "./context/checkoutContext"
import { SnackbarProvider } from "notistack"
import { Slide } from "@mui/material"

import RedirectPage from "./pages/redirectPage"
import OneTimePayement from "./pages/oneTimePayment"
import RecurringPayment from "./pages/recurringPayment"

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            retry: false,
        },
    },
})

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <SnackbarProvider
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                TransitionComponent={Slide}
                autoHideDuration={3000}
                preventDuplicate
            >
                <BrowserRouter>
                    <CheckoutProvider>
                        <Routes>
                            <Route
                                path="/checkout/:checkoutId/recurring"
                                element={<RecurringPayment />}
                            />
                            <Route
                                path="/checkout/:checkoutId/onetime"
                                element={<OneTimePayement />}
                            />
                            <Route path="/notify" element={<RedirectPage />} />
                        </Routes>
                    </CheckoutProvider>
                </BrowserRouter>
            </SnackbarProvider>
        </QueryClientProvider>
    )
}

export default App
